// @flow

import {stripQueryParameters} from './tile_request_cache';

class TileCacheDb {
    constructor(dbSetting) {
        this.tileDBReady = new Promise(((resolve, reject) => {
            //if (!dbSetting.dbName) reject("No dbSetting");
            const request = indexedDB.open(dbSetting.dbName, 1);

            request.onsuccess = event => resolve(event.target.result);

            request.onerror = () => reject(new Error("IndexedDB not supported here"));

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (db) {
                    const tileObjectStore = db.createObjectStore(dbSetting.dbStore, {keyPath: "url"});
                    tileObjectStore.createIndex("url", "url", {unique: true});
                    tileObjectStore.transaction.oncomplete = () => console.log("DB was create!");
                }
            };
        }));
    }

    get(request, dbSetting) {
        return this.tileDBReady.then((tileDB) => {
            return new Promise(((resolve, reject) => {
                const strippedURL = stripQueryParameters(request.url);
                const req = tileDB.transaction([dbSetting.dbStore], "readonly")
                    .objectStore(dbSetting.dbStore)
                    .get(strippedURL);

                req.onsuccess = () => req.result ? resolve(req.result.body) : resolve(false);

                req.onerror = (err) => reject(err);
            }));
        });
    }

    put(request, body, dbSetting) {
        return this.tileDBReady.then((tileDB) => {
            return new Promise(((resolve, reject) => {
                const req = tileDB.transaction([dbSetting.dbStore], "readwrite")
                    .objectStore(dbSetting.dbStore)
                    .put({url: stripQueryParameters(request.url), body});

                req.onsuccess = () => resolve(true);

                req.onerror = (err) => reject(err);
            }));
        });
    }
}

export default TileCacheDb;
